<template>
  <div class="video-card">
    <b-card no-body :class="{ 'card-active': active }">
      <video preload id="video" ref="video" :src="`${video.src}#t=1.5`"></video>

      <div class="rank">{{ video.rank }}</div>

      <b-card-body class="text-center">
        <b-card-title :title="video.name" class="video-title" />

        <h3 class="performance-title">
          <template v-if="jsonVersion < 6">
            {{ $t("page.results.score.globalPerformance.old.title") }}
          </template>
          <template v-else>
            {{ $t("page.results.score.globalPerformance.title") }}
          </template>
        </h3>
        <div class="performance-value">
          <span
            v-if="jsonVersion < 6"
            :style="{ color: globalPerformanceColor }"
          >
            {{ video.globalPerformance }}
          </span>
          <span v-else> {{ video.globalPerformance }}% </span>
        </div>

        <b-button
          @click="goToAnalysis()"
          class="mb-4  px-5 py-2"
          variant="red text-uppercase"
          style="font-weight: 700; font-size: 0.8rem"
        >
          {{ $t("button.viewAnalysis") }}
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["video", "active", "index"],

  data() {
    return {
      thumbnailBase64: ""
    };
  },

  computed: {
    ...mapState("analysis", ["jsonVersion"]),

    ...mapGetters("analysis", ["globalPerformanceColor"]),

    isGuestMode() {
      return this.$store.getters["analysis/isGuestMode"];
    }
  },

  methods: {
    goToAnalysis() {
      this.$store.commit("analysis/SET_COMPARISON", false);
      this.$store.commit("analysis/SET_CURRENT_VIDEO_INDEX", this.index);
      if (this.isGuestMode) {
        this.$router.push({
          name: "shareScore",
          params: { videoId: this.video.id }
        });
      } else {
        this.$router.push({
          name: "score",
          params: { videoId: this.video.id }
        });
      }
    }
  }
};
</script>

<style scoped>
#video {
  width: 100%;
  height: auto;
  max-height: 350px;
  /* object-fit: cover; */
  aspect-ratio: 16/9;
  background-color: #171A1F;
}

.rank {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 2em;
  display: flex;
  top: -30px;
  position: relative;
  margin-bottom: -10px;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  background-color: white;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 5px solid var(--primary);
}

.video-title {
  text-align: center;
  font-weight: 300;
  font-size: 1.5rem;
}

.performance-title {
  margin-top: 2rem;
  font-weight: 700;
  font-size: 0.9rem;
}

.performance-value {
  margin-bottom: 2rem;
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--gray-dark);
}

.card {
  border-radius: 0;
  border-width: 0;
  min-height: 600px;
  max-height: 800px;
}

.card-active {
  outline: 5px solid #7fe215;
}

.card-active .rank {
  border-color: #7fe215;
  color: #7fe215;
}
</style>

<style lang="scss" scoped></style>
